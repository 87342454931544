<template>
  <LayoutNext>
    <template #page-title>
      Upload Earnings Report
    </template>
    <div class="backdrop" v-if="loadingSpinner">
      <div class="image-container d-flex justify-content-center align-items-center">
        <div class="loader"></div>
        <img src="@/assets/cs-loader.png" alt="Loading image">
      </div>
    </div>
    <div class="d-md-flex d-block justify-content-center gap-4 m-auto">
      <MDBCard id="UploadFile" class="p-2 pb-1 mb-4 mb-md-0">
        <MDBCardHeader>
          <h5 class="mb-1 fw-bold">Upload File</h5>
          <p class="mb-0">Only excel files are accepted.</p>
        </MDBCardHeader>
        <MDBCardBody>
          <form>
            <MDBSelect v-model:options="monthsOptionUpload" v-model:selected="form.month" label="Month" class="mb-3" />
            <MDBSelect v-model:options="yearsOptionUpload" v-model:selected="form.year" label="Year" class="my-3" />
            <MDBFile @change="handleFileUpload($event)" class="my-3" />
          </form>
        </MDBCardBody>
        <MDBCardFooter class="py-3 d-flex justify-content-end">
          <MDBBtn class="fw-bold" type="submit" @click="submit" color="primary" size="sm">
            Upload
          </MDBBtn>
        </MDBCardFooter>
      </MDBCard>
      <MDBCard id="DownloadReport" class="p-2 pb-1">
        <MDBCardHeader>
          <h5 class="mb-1 fw-bold">Download Report</h5>
          <p class="mb-0">Download Monthly Report for Each Asset Label File.</p>
        </MDBCardHeader>
        <MDBCardBody>
          <form>
            <MDBSelect v-model:options="monthsOptionDownload" v-model:selected="formDownload.downloadMonth"
              label="Month" class="mb-3" />
            <MDBSelect v-model:options="yearsOptionDownload" v-model:selected="formDownload.downloadYear" label="Year"
              class="my-3" />
          </form>
        </MDBCardBody>
        <MDBCardFooter class="py-3 d-flex justify-content-end">
          <MDBBtn class="fw-bold" type="submit" @click="download" color="primary" size="sm">
            Download
          </MDBBtn>
        </MDBCardFooter>
      </MDBCard>
    </div>
    <MDBToast v-model="toastObject.state" :delay="2000" autohide position="top-right" appendToBody stacking
      width="350px" :color="toastObject.color" text="white" :icon="toastObject.icon">
      <template #title>
        {{ toastObject.title }}
      </template>
      {{ toastObject.message }}
    </MDBToast>
  </LayoutNext>
</template>

<script setup>
import {
  MDBBtn, MDBToast, MDBFile, MDBSelect, MDBCard, MDBCardHeader, MDBCardFooter, MDBCardBody
} from "mdb-vue-ui-kit";
import LayoutNext from "@/views/v3/LayoutNext.vue";
import { reactive, ref } from "vue";
import axios from "axios";
import { useTitle } from "@vueuse/core";

useTitle("Upload Earnings Report | Creator Shield");

const toastObject = ref({
  state: false,
  message: "",
  color: "",
  icon: "",
});

const form = reactive({
  month: "",
  year: "",
  file: [],
});

const formDownload = reactive({
  downloadMonth: "",
  downloadYear: "",
});

const handleFileUpload = (event) => {
  form.file = event.target.files[0];
};

const monthsOptionUpload = [
  { text: "January", value: 1 },
  { text: "February", value: 2 },
  { text: "March", value: 3 },
  { text: "April", value: 4 },
  { text: "May", value: 5 },
  { text: "June", value: 6 },
  { text: "July", value: 7 },
  { text: "August", value: 8 },
  { text: "September", value: 9 },
  { text: "October", value: 10 },
  { text: "November", value: 11 },
  { text: "December", value: 12 },
];

const monthsOptionDownload = JSON.parse(JSON.stringify(monthsOptionUpload));

const yearsOptionUpload = [];
const yearsOptionDownload = [];

const createYearsOption = () => {
  for (let year = 2022; year <= 2100; year++) {
    yearsOptionUpload.push({
      text: year,
      value: year,
    });
    yearsOptionDownload.push({
      text: year,
      value: year,
    });
  }
  return { yearsOptionUpload, yearsOptionDownload };
};

createYearsOption();
let loadingSpinner = ref(false);

const submit = () => {
  let formData = new FormData();
  formData.append("analytics", form.file);
  formData.append("month", form.month);
  formData.append("year", form.year);
  loadingSpinner.value = true;
  axios
    .post("api/analytics/UploadAnalytics", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(function (response) {
      if (response.status == 200) {
        toastObject.value.state = true;
        toastObject.value.title = "Success!";
        toastObject.value.message = "Uploaded Successfully!";
        toastObject.value.color = "success";
        toastObject.value.icon = "fas fa-check fa-lg me-2";
        loadingSpinner.value = false;
      }
    })
    .catch(function () {
      toastObject.value.state = true;
      toastObject.value.title = "Error!";
      toastObject.value.message = "An error occurred during request.";
      toastObject.value.color = "danger";
      toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
      loadingSpinner.value = false;
    });
};

const download = () => {
  loadingSpinner.value = true;
  axios
    .get(
      "api/revenues/ExportToExcel?Month=" +
      formDownload.downloadMonth +
      "&Year=" +
      formDownload.downloadYear,
      {
        responseType: "blob",
      }
    )
    .then(function (response) {
      if (response.status == 200) {
        let fileURL = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        let fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          "CreatorShield-AssetLabelMonthlyReport - " +
          formDownload.downloadMonth +
          " " +
          formDownload.downloadYear +
          ".xlsx"
        );
        document.body.appendChild(fileLink);

        fileLink.click();

        toastObject.value.state = true;
        toastObject.value.title = "Success!";
        toastObject.value.message = "Downloaded Successfully!";
        toastObject.value.color = "success";
        toastObject.value.icon = "fas fa-check fa-lg me-2";
        loadingSpinner.value = false;
      }
    })
    .catch(function () {
      toastObject.value.state = true;
      toastObject.value.title = "Error!";
      toastObject.value.message = "An error occurred during request.";
      toastObject.value.color = "danger";
      toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
      loadingSpinner.value = false;
    });
};
</script>

<style scoped>
.btn {
  background-color: var(--primary);
}
</style>

<style>
.analytics-loader {
  color: var(--accent);
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container .loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top-color: rgb(153, 153, 153);
  border-radius: 50%;
  animation: BorderSpin 1s linear infinite;
}

.image-container img {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

@keyframes BorderSpin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
